/**
 * Default configuration file. Any values also present in the .conf.js file will be
 * overridden. The configurations have two layers
 * 1. Category
 * 2. Value
 *
 * This system does not support nesting beyond these two layers. Any object value will
 * be replaced with the entire object value in the secret config
 *
 * All values marked as `undefined` must be defined in the .conf.js file unless the
 * `config.errorOnUndefined` value is set to `false`
 *
 * Environment variables can also be used to define values, using the following format for
 * the key: `NODE_CONF_${category}_${value}`
 */
module.exports = {
  /**
   * Configuration configurations (yo dawg)
   */
  config: {
    /**
     * If an error should be thrown for `undefined` configuration values
     */
    errorOnUndefined: false,
  },

  /**
   * General AWS configurations
   */
  aws: {
    /**
     * AWS region. MUST be us-east-1 (see IAC readme notes)
     */
    region: 'us-east-1',

    /**
     * AWS account Id
     */
    accountId: '709868317682',

    /**
     * Profile to use when running custom scripts that use the AWS CLI. Can also be passed in
     * as an argument (--profile)
     */
    profile: null,
  },

  /**
   * Configurations related to DNS management
   */
  dns: {
    /**
     * The root domain name for which a hosted zone exists in the AWS account
     */
    rootDomainName: 'mosaicvillage.us',

    /**
     * Subdomain Name for the frontend webapp. Set as a falsy value to use the apex domain
     */
    appSubdomain: 'app',

    /**
     * Subdomain Name for media files
     */
    mediaSubdomain: 'media',

    /**
     * Subdomain name for the API endpoint
     */
    apiSubdomain: 'api',
  },

  /**
   * Configurations for CloudFormation and CDK
   */
  cdk: {
    /**
     * Modifier to apply to resource names to guarantee uniqueness between different deployments.
     * Must be alphanumeric (no hyphens or periods) and all lower case. Will be applied as a
     * suffix to resource Ids, and will be applied as an additional subdomain to domain names
     */
    modifier: '',

    /**
     * ARN of the AWS Certificate Manager cert that will be used to secure connections. This
     * cert should be generated as part of first time project setup
     */
    certArn: 'arn:aws:acm:us-east-1:709868317682:certificate/27ae0648-6a50-4c58-a641-3a12f298aa8f',
  },

  /**
   * S3 configurations for various buckets
   */
  s3: {
    /**
     * Name of the bucket containing built frontend application code
     */
    appBucketName: 'mosaicvillage-frontend',

    /**
     * Media Configurations
     */
    media: {
      /**
       * Bucket route to access media files
       */
      bucket: 'mosaicvillage-media',

      /**
       * Prefix to access bucket content
       */
      prefix: 'media/',
    },
  },

  /**
   * Configurations for CloudFront distributions
   */
  cloudfront: {
    /**
     * TTL (in seconds) for CF Distributions.
     *   https://docs.aws.amazon.com/AmazonCloudFront/latest/DeveloperGuide/distribution-web-values-specify.html#DownloadDistValuesMinTTL)
     *
     * Set these all to 0 in .conf.js during development to inhibit caching.
     * As we enter production mode, these values should be set to the AWS defaults below:
        defaultTtl: 86400,
        minTtl: 0,
        maxTtl: 31536000,
     */
    defaultTtl: 0,
    minTtl: 0,
    maxTtl: 0,
  },

  /**
   * Configurations for Lambda functions
   */
  lambda: {
    /**
     * How long, in seconds, to allow the lambda to run for
     */
    timeout: 60,

    /**
     * How many times a failed Lambda function call should be retried
     */
    retryAttempts: 1,

    /**
     * Amount of memory, in MB, that is allocated to the Lambda function
     */
    memorySize: 128,
  }
};
